:root {
  --main-hue: 170;
  --theme-light: hsl(var(--main-hue) 50% 90%);
  --theme-semi-light: hsl(var(--main-hue) 50% 75%);
  --dark1: hsl(0, 0%, 5%);
  --dark2: hsl(0, 0%, 15%);
  --dark3: hsl(0, 0%, 20%);
  --nav-height: 4rem;
  --complement: #f2dfd9;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: clip;
}
#root > div {
  flex: 1;
}
.nav-component + div {
  background: var(--dark2);
  color: var(--theme-light);
}
body {
  margin: 0;
  font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
