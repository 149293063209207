.project-component {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project-component .project-title {
  text-align: center;
  margin-top: 2rem;
}
.project-component .project {
  margin-top: 2rem;
}
