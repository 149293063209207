.project-link {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  text-decoration: none;
  padding: 0.5rem;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  color: hsl(var(--main-hue), 50%, 50%);
  white-space: nowrap;
  transition: background-color 250ms cubic-bezier(0.1, 0.1, 0, 1);
}
.project-link:hover[href] {
  background-color: var(--dark3);
}
