.footer-component {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 100%;
  flex-shrink: 1;
  background-color: var(--dark2);
  color: var(--theme-light);
}
