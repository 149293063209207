.nav-component {
  display: flex;
  position: sticky;
  background: hsl(var(--main-hue) 100% 30%);
  height: var(--nav-height);
  top: 0;
  z-index: 5;
}
.nav-component .nav-menu {
  display: flex;
  align-items: center;
  gap: 2rem;
}
.nav-component .nav-menu .nav-item {
  list-style: none;
  margin: 0;
  padding: 0;
}
.nav-component .nav-menu .nav-item:first-child {
  margin-left: 2rem;
}
.nav-component .nav-menu .nav-item a {
  text-decoration: none;
  font-weight: bold;
  font-size: 1.25rem;
  color: var(--dark1);
  padding: 0.4rem;
  transition: background-color 250ms cubic-bezier(0.1, 0.1, 0, 1);
  border-radius: 4px;
}
.nav-component .nav-menu .nav-item a:hover {
  background-color: rgba(0 0 0 / 0.2);
}
