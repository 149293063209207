.resume-component .resume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  margin-bottom: 1rem;
}
.resume-component .resume-container a {
  text-decoration: none;
  color: hsl(var(--main-hue), 50%, 50%);
  margin: 1.5rem 0;
}
.resume-component .resume-container #resume {
  width: 70%;
  height: 85vh;
}
.resume-component .resume-container #resume + .mobile-resume-container {
  display: none;
}
@media screen and (max-width: 480px) {
  .resume-component .resume-container #resume {
    width: 100%;
    height: auto;
  }
}
.resume-component .resume-container .mobile-resume-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: fit-content;
}
.resume-component .resume-container .mobile-resume-container a {
  text-decoration: none;
  color: hsl(var(--main-hue), 50%, 50%);
}
.resume-component .resume-container .mobile-resume-container img#resume {
  width: 100%;
  height: auto;
}
.resume-component p {
  text-align: center;
}
